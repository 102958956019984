import React from "react";
 
const Feed = () => {
    return (
        <div>
            <h1>
                This is a feed
            </h1>
        </div>
    );
};
 
export default Feed;